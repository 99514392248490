import { useApi } from '../data/use-api';

export default function FileTitle({ file_id }) {
  const { data: file, error } = useApi(`/files/${file_id}`);

  if (!file || error) {
    return null;
  }

  return file.filename;
}
