import React from 'react';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Nav from 'react-bootstrap/Nav';
import { useApi } from '../data/use-api';
import Spinner from '../components/spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import { norms } from '../utils/norms';

export default function Wordcloud() {
  const { audit_id } = useParams();
  const { data: frequencyCloud, error: frequencyCloudError } = useApi(
    `/audits/${audit_id}/terms?limit=35`
  );
  const { data: predefinedCloud, error: predefinedCloudError } = useApi(
    `/audits/${audit_id}/predefined-terms`
  );
  const { data: predefinedTerms, error: termsError } = useApi(
    '/word-cloud/terms-per-norm'
  );

  const predefinedCounts = React.useMemo(() => {
    if (predefinedCloud == null) return null;
    return _.mapValues(_.groupBy(predefinedCloud, 'text'), '0.frequency');
  }, [predefinedCloud]);

  const termsByNorm = React.useMemo(() => {
    if (predefinedTerms == null || predefinedCounts == null) {
      return null;
    }
    return predefinedTerms.map((normTerms) =>
      normTerms.map((terms) =>
        terms
          .split(', ')
          .map((text) => ({ text, frequency: predefinedCounts[text] }))
      )
    );
  }, [predefinedTerms, predefinedCounts]);

  return (
    <>
      <div className="list__name">
        <h4 className="bp3-heading">Wordcloud</h4>
        <p className="small opacity-50 fst-italic">
          De getallen achter een woord geven aan hoe frequent het voorkomt in
          alle bestanden. Klik op een woord om erop te zoeken in alle bestanden.
        </p>
      </div>
      <Tab.Container
        defaultActiveKey="predefined"
        id="wordcloud"
        className="mb-3"
      >
        <Nav
          variant="pills"
          className="bg-white fw-bold p-2 rounded-pill justify-content-stretch gap-2 mb-3"
        >
          <Nav.Item
            className="flex-grow-1 text-center rounded-pill border border-primary"
            style={{ clipPath: 'border-box' }}
          >
            <Nav.Link eventKey="predefined">Suggestie CBCT</Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="flex-grow-1 text-center rounded-pill border border-primary"
            style={{ clipPath: 'border-box' }}
          >
            <Nav.Link eventKey="frequency">Top 35</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="predefined" title="CBCT Termen">
            <PredefinedCloud
              termsByNorm={termsByNorm}
              error={predefinedCloudError || termsError}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="frequency" title="Top 35">
            <FrequencyCloud
              terms={frequencyCloud}
              error={frequencyCloudError}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

const PredefinedCloud = ({ termsByNorm, error }) => {
  const { audit_id } = useParams();
  if (termsByNorm == null) {
    return <Spinner />;
  }
  if (error) {
    return <h3 className="bp3-heading">Kon geen termen ophalen</h3>;
  }
  function searchForTerm(term) {
    return {
      pathname: `/audits/${audit_id}/search`,
      search: `?q=${term.text}`,
    };
  }

  return (
    <div className="d-flex flex-column gap-2">
      {norms.map((name, nIndex) => (
        <div
          className="d-flex flex-column align-items-start gap-2 mb-2 position-relative"
          key={nIndex}
        >
          <h5 className="bp3-heading">{name}</h5>
          <div className="d-flex flex-wrap gap-1">
            {termsByNorm[nIndex].map((terms, tIndex) => (
              <ButtonGroup key={tIndex}>
                {terms.map((term, index) => (
                  <Button
                    as={Link}
                    to={searchForTerm(term)}
                    title={term.text}
                    variant="primary-shade-1"
                    className="border border-white text-white"
                    key={index}
                  >
                    {term.text}{' '}
                    <span
                      style={{
                        opacity: 0.67,
                        fontSize: '80%',
                      }}
                    >
                      {term.frequency}
                    </span>
                  </Button>
                ))}
              </ButtonGroup>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const FrequencyCloud = ({ terms, error }) => {
  const { audit_id } = useParams();
  if (terms == null) {
    return <Spinner />;
  }
  const large = Math.ceil(terms.length / 7);
  const medium = Math.floor((terms.length / 2 - large) / 2 + 0.5) * 2 + large;

  function searchForTerm(term) {
    return {
      pathname: `/audits/${audit_id}/search`,
      search: `?q=${term.text}`,
    };
  }
  if (terms && terms.length === 0) {
    return (
      <div className="wordcloud">
        <h3 className="bp3-heading">Er is geen wordcloud beschikbaar</h3>
      </div>
    );
  }
  if (error) {
    return (
      <div className="wordcloud">
        <h3 className="bp3-heading">Kon geen wordcloud ophalen</h3>
      </div>
    );
  }

  return (
    <div className="wordcloud__grid">
      {terms.map((term, index) => (
        <Button
          as={Link}
          key={index}
          className={classNames(
            'wordcloud__grid-item',
            index < large ? 'large' : index < medium ? 'medium' : 'small'
          )}
          to={searchForTerm(term)}
          title={term.text}
        >
          <h4>
            <div>{term.text}</div>
            <div
              style={{
                opacity: 0.67,
                fontSize: '80%',
              }}
            >
              {term.frequency}
            </div>
          </h4>
        </Button>
      ))}
    </div>
  );
};
