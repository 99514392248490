import { createClient } from '@supabase/supabase-js';

function apiSettings() {
  // In production, the variables will be in window.REACT_APP_SUPABASE_URL and window.REACT_APP_SUPABASE_ANON_KEY
  // In development, they will be in process.env.REACT_APP_SUPABASE_URL and process.env.REACT_APP_SUPABASE_ANON_KEY
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || window.supabaseUrl;
  const supabaseAnonKey =
    process.env.REACT_APP_SUPABASE_ANON_KEY || window.supabaseAnonKey;

  if (
    supabaseUrl.startsWith('REACT_APP_') ||
    supabaseAnonKey.startsWith('REACT_APP_')
  ) {
    throw new Error(
      'The Supabase URL and Anon Key must be replaced in the production server runtime.'
    );
  }

  return { supabaseUrl, supabaseAnonKey };
}

const { supabaseUrl, supabaseAnonKey } = apiSettings();
export const supabase = createClient(supabaseUrl, supabaseAnonKey);
