import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useParams, createSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Nav from '../components/nav';
import Wordcloud from '../components/wordcloud';
import { useApi } from '../data/use-api';
import Spinner from '../components/spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import FileIcon from '../components/file-icon';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Searchbar from '../components/searchbar';
import { norms, group_by_norm } from '../utils/norms';
import { toHumanReadableDate } from '../utils/dates';

const InfoCard = ({ audit }) => {
  const dates = [audit.audit_date_1, audit.audit_date_2, audit.audit_date_3]
    .filter((d) => d)
    .map((d) => new Date(d))
    .sort((a, b) => a - b)
    .map(toHumanReadableDate)
    .join(', ');
  const zenya_url = `https://cbct.zenya.work/iTask/Tasks/SummaryFrame.aspx?OpenedTab=Overview&TaskID=${audit.zenya_id}`;
  return (
    <Card variant="light">
      <Card.Body>
        <Card.Title>
          <h4>
            {audit.auditee}{' '}
            <span className="opacity-50" style={{ fontSize: '1rem' }}>
              ({audit.auditee_number})
            </span>
          </h4>
        </Card.Title>
        <Card.Text>
          <b>Audit data</b>: {dates}
          <br />
          <b>Auditoren</b>: {audit.auditors}
        </Card.Text>
        <Button
          size="sm"
          variant="outline-primary"
          href={zenya_url}
          target="_blank"
        >
          Bekijk audit in Zenya
        </Button>
      </Card.Body>
    </Card>
  );
};

const FileItem = ({ file }) => {
  const isPdf =
    file.filetype === 'pdf' || file.filename.toLowerCase().endsWith('.pdf');
  let errorMessage = null;
  if (file.import_failed) {
    errorMessage = isPdf
      ? 'Importeren mislukt. Je kunt dit bestand wel lezen maar er niet in zoeken. Zie ook de Handleiding Infozoeker voor meer informatie.'
      : 'Importeren mislukt. Je moet dit bestand bekijken in Zenya. Zie ook de Handleiding Infozoeker voor meer informatie.';
  }
  return (
    <ListGroup.Item key={file.id} className="d-flex align-items-center py-3">
      {!file.import_failed ? (
        <FileIcon file={file} className="me-2" />
      ) : (
        <div className="me-2">
          <i
            className={`bi bi-exclamation-triangle${isPdf ? '' : '-fill'}`}
            style={{ fontSize: '1.5rem' }}
          ></i>
        </div>
      )}
      <div>
        <div>{file.filename}</div>
        <div className="small opacity-50">
          {!errorMessage ? (
            <>
              {file.page_count} pagina{file.page_count > 1 ? "'s" : null}
            </>
          ) : (
            errorMessage
          )}
        </div>
      </div>
      <div className="ms-auto align-items-center flex-shrink-0">
        <ButtonGroup>
          <Button
            as={Link}
            to={{
              pathname: `/audits/${file.audit_id}/search`,
              search: `${createSearchParams({
                file_id: file.id,
                view: 'read',
              })}`,
            }}
            variant="outline-primary"
            className={classNames({
              disabled: file.filetype !== 'pdf',
            })}
          >
            <i className="bi bi-book me-1"></i>
            Lees
          </Button>
          <Button
            as={Link}
            disabled={
              file.import_failed ||
              file.page_count == null ||
              file.page_count === 0
            }
            to={{
              pathname: `/audits/${file.audit_id}/search`,
              search: `${createSearchParams({
                file_id: file.id,
                fileFilter: file.id,
              })}`,
            }}
            variant="outline-primary"
            className={classNames({
              disabled:
                file.import_failed ||
                file.page_count == null ||
                file.page_count === 0,
            })}
          >
            <i className="bi bi-search me-1"></i>
            Zoek
          </Button>
        </ButtonGroup>
      </div>
    </ListGroup.Item>
  );
};

const FileList = ({ files }) => {
  if (files.length === 0) {
    return (
      <ListGroup.Item>
        <small className="opacity-50">
          Er zijn geen bestanden bij deze norm geüpload.
        </small>
      </ListGroup.Item>
    );
  }
  return (
    <>
      {files.map((file) => (
        <FileItem key={file.id} file={file} />
      ))}
    </>
  );
};

const FilesByNorm = ({ files, audit_id }) => {
  const files_by_norm = React.useMemo(() => group_by_norm(files), [files]);

  return (
    <>
      {norms.map((name, i) => {
        const files = files_by_norm[i + 1] ? files_by_norm[i + 1] : [];
        return (
          <ListGroup key={`norm-${i}`} className="mb-3">
            <ListGroup.Item variant="primary  d-flex justify-content-between align-items-center">
              <h5 className="mb-0 fw-bold">{name}</h5>
              {files.length > 0 ? (
                <Button
                  as={Link}
                  to={{
                    pathname: `/audits/${audit_id}/search`,
                    search: `${createSearchParams({
                      fileFilter: `norm-${i + 1}`,
                    })}`,
                  }}
                  variant="outline-primary"
                >
                  <i className="bi bi-search me-1"></i>
                  Zoek
                </Button>
              ) : null}
            </ListGroup.Item>
            <FileList files={files} />
          </ListGroup>
        );
      })}
    </>
  );
};

export default function Audit() {
  const { audit_id } = useParams();
  const navigate = useNavigate();
  const { data: audit /* error: _auditError */ } = useApi(
    `/audits/${audit_id}`
  );
  const { data: files /* error: _filesError */ } = useApi(
    `/files/?audit_id=${audit_id}`
  );

  function handleSearch(query) {
    navigate(`/audits/${audit_id}/search?q=${query}`);
  }

  return (
    <>
      <Nav />
      <main className="container pb-3">
        <div className="mb-3" />
        {!audit || !files ? (
          <Spinner className="m-5" />
        ) : (
          <>
            <h1>{audit.organization}</h1>
            <div className="mb-3" />
            <Searchbar
              fileFilterDropdown={false}
              query=""
              setQuery={handleSearch}
            />
            <div className="mb-3" />
            <div className="audit__layout" style={{ columnGap: '2rem' }}>
              <div className="list">
                <h4 className="bp3-heading">Bestanden</h4>
                <p className="small opacity-50 fst-italic">
                  Dit zijn alle bestanden die de auditee heeft geüpload. Gebruik
                  de knoppen om een bestand te lezen of erin te zoeken.
                </p>
                {files && <FilesByNorm files={files} audit_id={audit_id} />}
              </div>
              <div className="list">
                <InfoCard audit={audit} />
                <div className="mb-4" />
                <Wordcloud />
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
}
