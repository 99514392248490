import { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { Form, Tab, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { host } from '../../data/use-api';
import classNames from 'classnames';

const Email = ({ value, set }) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(value);
  const kwaliteitsbeeld = value.endsWith('@kwaliteitsbeeld.nl');
  return (
    <div>
      <Form.Control
        className="rounded-pill px-3"
        placeholder="E-mail"
        type="email"
        value={value}
        isValid={isValid}
        onChange={(e) => set(e.target.value)}
      />
      {isValid && !kwaliteitsbeeld ? (
        <div className="ms-3">
          <Form.Text>
            Dit lijkt geen @kwaliteitsbeeld.nl e-mailadres te zijn. Weet je
            zeker dat dit klopt?
          </Form.Text>
        </div>
      ) : null}
    </div>
  );
};

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tab, setTab] = useState('password');

  const callAPI = async (endpoint, data) => {
    return await fetch(`${host}/auth${endpoint}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => (res.ok ? res.json() : null));
  };

  const signInWithOtp = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: { redirectTo: window.location },
    });

    if (error) {
      console.error(error);
      alert(error.error_description || error.message);
    } else {
      alert('Check je e-mail voor een login link!');
    }
    setLoading(false);
  };

  const resetPasswordForEmail = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: window.location.origin + '/auth/reset-password',
    });
    if (error) {
      console.error(error);
      alert(error.error_description || error.message);
    } else {
      alert('Check je e-mail voor een reset link!');
    }
    setLoading(false);
  };

  const signInWithPassword = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      const { data } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (data && 'user' in data) {
        return;
      }
      await callAPI('/auth0-sign-in', { email, password });
      let { error: error2 } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error2) {
        throw error2;
      }
    } catch (error2) {
      setLoading(false);
      alert(error2.error_description || error2.message);
    }
  };

  return (
    <main className="d-flex flex-column align-items-center justify-content-center pb-5 vh-100 vw-100 bg-black">
      <Card
        // className="mb-3 bg-white p-5 border border rounded"
        style={{ width: '800px' }}
      >
        <Card.Header className="px-5 py-4">
          <h1 className="mt-3">Infozoeker Inloggen</h1>
          <p className="lead mb-0 ">
            Gebruik je{' '}
            <span className="fw-bold text-primary">@kwaliteitsbeeld.nl</span>{' '}
            e-mailadres om in te loggen.
          </p>
        </Card.Header>
        <Tab.Container activeKey={tab} onSelect={(k) => setTab(k)}>
          <Row className="m-0">
            <Col sm={5} className="p-0 border-end">
              <ListGroup variant="flush" className="border-bottom">
                <ListGroup.Item
                  className={classNames(
                    tab === 'magiclink' ? 'bg-primary text-white' : '',
                    'cursor-pointer px-5'
                  )}
                  onClick={() => setTab('magiclink')}
                >
                  Inloglink{' '}
                  <span className="badge text-bg-warning rounded-pill">
                    nieuw
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={classNames(
                    tab === 'password' ? 'bg-primary text-white' : '',
                    'cursor-pointer px-5'
                  )}
                  onClick={() => setTab('password')}
                >
                  E-mail en wachtwoord
                </ListGroup.Item>
                <ListGroup.Item
                  className={classNames(
                    tab === 'forgot-password' ? 'bg-primary text-white' : '',
                    'cursor-pointer px-5'
                  )}
                  onClick={() => setTab('forgot-password')}
                >
                  Wachtwoord vergeten?
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col
              sm={7}
              className="p-0 d-flex justify-content-stretch align-content-stretch"
              style={{ minHeight: '400px' }}
            >
              <Card.Body className="p-5">
                <Tab.Content className="d-flex justify-content-center align-conten-center flex-grow-1">
                  <Tab.Pane
                    className="w-100"
                    eventKey="magiclink"
                    title="Inloglink"
                  >
                    <p>
                      Vul je e-mailadres in om een inloglink opgestuurd te
                      krijgen.
                    </p>
                    <form
                      className="d-flex flex-column gap-3"
                      onSubmit={signInWithOtp}
                    >
                      <Email value={email} set={setEmail} />
                      <button
                        className={'btn btn-primary rounded-pill block'}
                        onClick={signInWithOtp}
                        disabled={loading || email === ''}
                      >
                        {loading ? (
                          <span>Laden</span>
                        ) : (
                          <span>Stuur inloglink</span>
                        )}
                      </button>
                    </form>
                  </Tab.Pane>
                  <Tab.Pane
                    className="w-100"
                    eventKey="password"
                    title="Email en wachtwoord"
                  >
                    <p>Vul je e-mailadres en wachtwoord in.</p>
                    <p className="small text-muted">
                      Dit is het nieuwe inlogscherm, maar je inloggegevens zijn
                      nog hetzelfde.
                    </p>
                    <form
                      className="d-flex flex-column gap-3"
                      onSubmit={signInWithPassword}
                    >
                      <Email value={email} set={setEmail} />
                      <Form.Control
                        className="rounded-pill px-3 w-100"
                        placeholder="Wachtwoord"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <button
                        className={'btn btn-primary rounded-pill block'}
                        onClick={signInWithPassword}
                        disabled={loading || email === '' || password === ''}
                      >
                        {loading ? <span>Laden</span> : <span>Log in</span>}
                      </button>
                      <p className="small text-muted ms-3">
                        Wachtwoord vergeten?{' '}
                        <Link to="#" onClick={() => setTab('forgot-password')}>
                          Klik hier
                        </Link>
                      </p>
                    </form>
                  </Tab.Pane>
                  <Tab.Pane
                    className="w-100"
                    eventKey="forgot-password"
                    title="Wachtwoord vergeten"
                  >
                    <p>
                      Vul je e-mailadres in om een herstel link opgestuurd te
                      krijgen.
                    </p>
                    <form
                      className="d-flex flex-column gap-3"
                      onSubmit={resetPasswordForEmail}
                    >
                      <Email value={email} set={setEmail} />
                      <button
                        className={'btn btn-primary rounded-pill block'}
                        onClick={resetPasswordForEmail}
                        disabled={loading || email === ''}
                      >
                        {loading ? (
                          <span>Laden</span>
                        ) : (
                          <span>Stuur herstel e-mail</span>
                        )}
                      </button>
                    </form>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    </main>
  );
}
