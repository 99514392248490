import { useEffect, useRef, useState, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useApi } from '../data/use-api';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { FormLabel } from 'react-bootstrap';
import { norms, group_by_norm } from '../utils/norms';

const FileFilterDropdown = ({ files, fileFilter, setFileFilter }) => {
  if (!files) return null;
  const files_by_norm = group_by_norm(files);
  const options = norms
    .map((norm, i) => {
      const files = files_by_norm[i + 1] || [];
      return [
        <option key={`line-top-${i}`} disabled>
          ──────────
        </option>,
        <option
          key={norm}
          value={`norm-${i + 1}`}
          disabled={files.length === 0}
        >
          {norm.toUpperCase()}
        </option>,
        <option key={`line-bottom-${i}`} disabled>
          {' '}
        </option>,
        files.length === 0 ? (
          <option key={`no-files-${i}`} disabled>
            Geen bestanden
          </option>
        ) : null,
        ...files.map((file) => (
          <option key={`${i}-${file.id}`} value={file.id}>
            {'  '} {file.filename}
          </option>
        )),
      ];
    })
    .flat();
  return (
    <>
      <FormLabel className="flex-shrink-0 mb-0 small fw-bold opacity-50">
        binnen
      </FormLabel>
      <Form.Select
        aria-label="Filter op bestand"
        onChange={(e) => setFileFilter(e.target.value)}
        value={fileFilter || ''}
        key={fileFilter}
        className={classNames({ 'text-muted': !fileFilter }, 'rounded-pill')}
      >
        <option value="">alle bestanden</option>
        {options.map((option) => option)}
      </Form.Select>
    </>
  );
};

const SearchInput = forwardRef(({ value, ...props }, ref) => {
  const [localValue, setLocalValue] = useState(value);

  return (
    <Form.Control
      ref={ref}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      {...props}
    />
  );
});

export default function SearchBar({
  query,
  setQuery,
  fileFilter,
  setFileFilter,
  normFilter,
  setNormFilter,
  autoFocus = false,
}) {
  const { audit_id } = useParams();
  const { data: files /* error: _filesError */ } = useApi(
    `/files/?audit_id=${audit_id}`
  );

  const inputRef = useRef(null);

  function handleSumbit(e) {
    e.preventDefault();
    const { value } = inputRef.current;
    if (value !== '') setQuery(value);
  }

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, fileFilter, autoFocus]);

  return (
    <section
      className="sticky-top py-3"
      style={{ backgroundColor: 'var(--background)' }}
    >
      <div className="bg-white rounded-pill py-2 px-4 border">
        <Form onSubmit={handleSumbit}>
          <div className="d-flex align-items-center w-100 justify-content-stretch gap-3">
            <FormLabel className="flex-shrink-0 mb-0 small fw-bold opacity-50">
              Zoek naar
            </FormLabel>
            <InputGroup className="d-flex align-items-center">
              <SearchInput
                ref={inputRef}
                autoFocus={autoFocus}
                type="text"
                placeholder={null}
                value={query}
                key={query}
                // onBlur={handleSumbit}
                className="flex-grow-1 py-1 border-0 border-bottom rounded-0 pe-4"
              />
              {query != null && query !== '' ? (
                <i
                  className="bi bi-x cursor-pointer position-absolute"
                  style={{ cursor: 'pointer', right: '0.1rem', zIndex: 1000 }}
                  onClick={() => setQuery('')}
                ></i>
              ) : null}
            </InputGroup>
            {setFileFilter ? (
              <FileFilterDropdown
                files={files}
                fileFilter={fileFilter}
                setFileFilter={setFileFilter}
                normFilter={normFilter}
                setNormFilter={setNormFilter}
              />
            ) : null}
            <Button
              variant="info"
              type="submit"
              className="rounded-pill flex-shrink-0"
            >
              <i className="bi bi-search me-2"></i>
              Zoek
            </Button>
          </div>
        </Form>
      </div>
    </section>
  );
}
