import { useEffect } from 'react';
import { supabase } from '../../supabaseClient';

export default function Logout() {
  useEffect(() => {
    async function signOut() {
      await supabase.auth.signOut();
    }
    signOut();
  }, []);

  return <div />;
}
