import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { Form, Card, Alert } from 'react-bootstrap';
import { host } from '../../data/use-api';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [repeat, setRepeat] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getSession() {
      const {
        data: {
          session: {
            access_token,
            user: { email },
          },
        },
      } = await supabase.auth.getSession();
      setToken(access_token);
      setEmail(email);
    }
    getSession();
  }, []);

  console.log('Token', token);

  async function callAPI(endpoint, data) {
    const res = await fetch(`${host}/auth${endpoint}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      console.log('done');
      return;
    }
    console.log('Error', res);
    const body = await res.json();
    throw new Error(String(body.errors));
  }

  const updatePassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors([]);
    try {
      await callAPI('/reset-password', { password });
      console.log('Password updated');
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      console.log(data, error);
      if (error) {
        console.error(error);
        throw new Error('Failed to sign in');
      }
      alert('Wachtwoord is ingesteld!');
      navigate('/');
    } catch (err) {
      // console.error(err);
      if (err.message.includes('too weak')) {
        setErrors((prev = []) => [...prev, 'Wachtwoord is te zwak']);
      }
      setLoading(false);
    }
  };

  const checkPasswordStrength = () => {
    // At least 10 characters
    if (password.length < 10) return false;
    return true;
  };

  return (
    <main className="d-flex flex-column align-items-center justify-content-center pb-5 vh-100 vw-100 bg-black">
      <Card
        // className="mb-3 bg-white p-5 border border rounded"
        style={{ width: '800px' }}
      >
        <Card.Header className="px-5 py-4">
          <h1 className="mt-3">Wachtwoord instellen</h1>
          <p className="lead mb-0 ">Voer je nieuwe wachtwoord in.</p>
        </Card.Header>
        <Card.Body className="p-5">
          <p>
            <strong>E-mail:</strong> {email}
          </p>
          <form onSubmit={updatePassword} className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Control
                className="rounded-pill px-3 w-100"
                placeholder="Wachtwoord"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isValid={checkPasswordStrength()}
                isInvalid={password !== '' && !checkPasswordStrength()}
              />
              {password && !checkPasswordStrength() && (
                <Form.Control.Feedback type="invalid" className="ms-3">
                  Wachtwoord moet minstens 10 karakters bevatten
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                className="rounded-pill px-3 w-100"
                margin="normal"
                label="Herhaal wachtwoord"
                placeholder="Herhaal wachtwoord"
                type="password"
                autoComplete="new-password"
                value={repeat}
                onChange={(e) => setRepeat(e.target.value)}
                isValid={
                  password !== '' && repeat !== '' && password === repeat
                }
                isInvalid={
                  password !== '' && repeat !== '' && password !== repeat
                }
              />
              {password !== '' && repeat !== '' && password !== repeat ? (
                <Form.Control.Feedback type="invalid" className="ms-3">
                  Wachtwoorden komen niet overeen
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <button
              className={'btn btn-primary rounded-pill block'}
              onClick={updatePassword}
              disabled={
                loading || !checkPasswordStrength() || password !== repeat
              }
            >
              Stel wachtwoord in
            </button>
            {errors &&
              errors.map((error) => (
                <Alert variant="danger" key={error}>
                  {error}
                </Alert>
              ))}
          </form>
        </Card.Body>
      </Card>
    </main>
  );
};

export default ResetPassword;
