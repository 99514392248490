import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import nl from 'date-fns/locale/nl';
import formatDistance from 'date-fns/formatDistance';

const dateToDate = (date) => {
  if (date instanceof Date) return date;
  return parseISO(date);
};

/**
 * Parses an ISO date string into human readable date
 *
 * @param {string} date ISO date string or Date object
 * @returns {string} Human readable date in NL locale
 */
export function toHumanReadableDate(date) {
  if (!date) return;
  return formatDate(dateToDate(date), 'd LLLL yyyy', { locale: nl });
}

/**
 *
 * @param {string} date ISO date string or Date object
 * @returns {string} Human readable time diff
 */
export const toHumanReadableTimeDiff = (date) => {
  return formatDistance(dateToDate(date), new Date(), {
    locale: nl,
    addSuffix: true,
  });
};
