export const norms = [
  'Norm 1', //: Beleid & financiën',
  'Norm 2', //: Middelen',
  'Norm 3', //: Samenwerking',
  'Norm 4', //: Mensen',
  'Norm 5', //: Producten & diensten',
  'Norm 6', //: Resultaten & maatschappelijk effect',
  'Norm 7', //: Handelen conform (privacy)wetgeving',
];

export const group_by_norm = (list) => {
  const by_norm = {};
  list.forEach((obj) => {
    obj.norms.forEach((norm) => {
      if (by_norm[norm] == null) {
        by_norm[norm] = [];
      }
      by_norm[norm].push(obj);
    });
  });
  return by_norm;
};
