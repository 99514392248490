import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { Button, Card } from 'react-bootstrap';

const Confirm = () => {
  const [searchParams] = useSearchParams();
  const token_hash = searchParams.get('token_hash') || '';
  const link_type = searchParams.get('type') || '';

  const handleLogin = async (event) => {
    event.preventDefault();
    // Call the login function
    const { error } = await supabase.auth
      .verifyOtp({
        token_hash,
        type: 'email',
      })
      .then(() => {
        if (link_type === 'resetpassword') {
          window.location.replace('/auth/reset-password');
        }
      });
    if (error) {
      console.error('Error:', error.message);
      alert('Er is iets misgegaan');
    }
  };

  if (link_type === 'resetpassword') {
    return (
      <main className="d-flex flex-column align-items-center justify-content-center pb-5 vh-100 vw-100 bg-black">
        <Card>
          <Card.Body className="p-5">
            <h1 className="mt-3">Wachtwoord herstellen</h1>
            <p className="lead mb-5">
              Klik op de knop om uw wachtwoord te herstellen
            </p>
            <Button size="lg" onClick={handleLogin}>
              Herstellen
            </Button>
          </Card.Body>
        </Card>
      </main>
    );
  }
  return (
    <main className="d-flex flex-column align-items-center justify-content-center pb-5 vh-100 vw-100 bg-black">
      <Card>
        <Card.Body className="p-5">
          <h1 className="mt-3">Infozoeker inloggen</h1>
          <p className="lead mb-5 ">Klik op de knop om in te loggen</p>
          <Button size="lg" onClick={handleLogin}>
            Inloggen
          </Button>
        </Card.Body>
      </Card>
    </main>
  );
};

export default Confirm;
