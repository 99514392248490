import useSWR from 'swr';
import { supabase } from '../supabaseClient';

export const host =
  window.apiHost !== 'REPLACE_API_HOST'
    ? window.apiHost
    : process.env.REACT_APP_API_HOST || 'http://localhost:8000';

export function useApi(url) {
  return useSWR(url, async (route) => {
    const {
      data: {
        session: { access_token: token },
      },
    } = await supabase.auth.getSession();
    if (url === null) {
      return null;
    }
    try {
      // const token = session.access_token;
      const url = `${host}${route}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return await res.json();
      }

      if (res.status === 404) {
        throw new Error('Not found');
      }

      if (res.status === 422) {
        // TODO: parse validation error
        throw new Error('Validation error');
      }

      if (res.status === 500) {
        throw new Error('Internal server error');
      }
    } catch (err) {
      console.error(`HTTP Error for url (${url}): `, err.message, host);
      throw err;
    }
  });
}
