import classNames from 'classnames';

const Spinner = ({ variant = 'primary-shade-2', className, size = '3rem' }) => (
  <div
    className={classNames(
      'd-block spinner-border text-center mx-auto',
      className,
      `text-${variant}`
    )}
    style={{ width: size, height: size }}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </div>
);
export default Spinner;
