import React, { useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import {
  Link,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { useApi } from '../data/use-api';
import FileTitle from './file-title';
import { supabase } from '../supabaseClient';

export default function Nav() {
  const { audit_id } = useParams();
  const { data: audit } = useApi(audit_id ? `/audits/${audit_id}` : null);

  // Breadcrumb
  const { pathname } = useLocation();
  const [urlSearchParams] = useSearchParams();
  const file_id = urlSearchParams.get('file_id');
  const searchPage =
    pathname
      .split('/')
      .filter((d) => d.length)
      .at(-1) === 'search';

  const handleKeyPress = useCallback((event) => {
    const { data: session } = supabase.auth.getSession();
    const copyToken = async () => {
      console.log('Copying token to clipboard');
      console.log('access_token', session.access_token);
      if (session && session.access_token) {
        navigator.clipboard.writeText(session.access_token);
      }
    };

    if (event.ctrlKey && event.shiftKey) {
      if (event.key === 'c' || event.key === 'C') {
        console.log(`Key pressed: ${event.key}`);
        copyToken();
      }
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  let AuditBreadcrumbComponent;
  let auditBreadCrumbProps = {
    className: 'd-flex align-items-center gap-1',
  };
  if (audit && searchPage) {
    AuditBreadcrumbComponent = Button;
    auditBreadCrumbProps = {
      ...auditBreadCrumbProps,
      variant: 'outline-white',
      as: Link,
      to: `/audits/${audit_id}`,
    };
  } else {
    AuditBreadcrumbComponent = 'div';
    auditBreadCrumbProps = {
      ...auditBreadCrumbProps,
      className: auditBreadCrumbProps.className + ' text-white',
    };
  }

  return (
    <>
      <Navbar className="bg-primary">
        <div className="container align-items-center gap-2">
          <Button variant="outline-white" as={Link} to="/audits">
            <i className="bi bi-house-door-fill me-1" />
            Alle Audits
          </Button>
          {audit ? (
            <>
              <i className="bi bi-chevron-right text-white" />
              <AuditBreadcrumbComponent {...auditBreadCrumbProps}>
                <i className="bi bi-files me-1" />
                <span
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: file_id ? '250px' : '900px' }}
                >
                  {audit.auditee}
                </span>{' '}
                <small className="opacity-50">({audit.auditee_number})</small>
              </AuditBreadcrumbComponent>
              {file_id ? (
                <>
                  <i className="bi bi-chevron-right text-white" />
                  <div className="d-flex align-items-center text-white">
                    <i className="bi bi-file-pdf me-1" />
                    <span
                      className="d-inline-block  text-truncate"
                      style={{ maxWidth: '650px' }}
                    >
                      <FileTitle file_id={file_id} />
                    </span>
                  </div>
                </>
              ) : searchPage ? (
                <>
                  <i className="bi bi-chevron-right text-white" />
                  <div className="d-flex align-items-center text-white">
                    <i className="bi bi-search me-1" />
                    Zoeken
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          <div className="ms-auto">
            <Dropdown>
              <Dropdown.Toggle variant="outline-white" id="dropdown-basic">
                <i className="bi bi-person-circle me-1" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item to="/auth/logout" as={Link}>
                  Uitloggen
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
}
