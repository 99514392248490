import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Nav from '../components/nav';
import { toHumanReadableDate, toHumanReadableTimeDiff } from '../utils/dates';
import { useApi } from '../data/use-api';
import Spinner from '../components/spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { ListGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { min, max } from 'date-fns';

const Audit = ({ audit }) => {
  const dates = [audit.audit_date_1, audit.audit_date_2, audit.audit_date_3]
    .filter((d) => d)
    .map((d) => new Date(d))
    .sort((a, b) => a - b);
  const minDate = min(dates);
  const maxDate = max(dates);
  const inTheFuture = minDate ? minDate > new Date() : false;
  const inThePast = maxDate ? maxDate < new Date() : false;
  const current = !inThePast && !inTheFuture;
  return (
    <ListGroup.Item
      action
      as={Link}
      to={`/audits/${audit.id}`}
      className={classNames('d-flex gap-3 py-3', { 'opacity-50': inThePast })}
      title={`${audit.auditee} (${audit.auditee_number})\n${
        audit.auditors
      }\n${dates.join(', ')}\nID in Zenya: ${audit.zenya_id}`}
    >
      <i
        className={classNames('bi align-self-center fs-3', {
          'text-primary': !inThePast && !audit.import_status === 'failed',
          'text-danger': audit.import_status === 'failed',
          'bi-check-circle': audit.import_status === 'success',
          'bi-x-circle': audit.import_status === 'failed',
          'bi-hourglass':
            audit.import_status === 'importing' ||
            audit.import_status === 'queued',
        })}
      ></i>
      <div className="d-flex gap-2 w-100 justify-content-between">
        <div>
          <h6 className="mb-0">
            {audit.auditee}{' '}
            <small className="opacity-50">({audit.auditee_number})</small>
          </h6>
          {dates.length
            ? dates.map((d, i) => (
                <p key={i} className="mb-0 opacity-75">
                  {toHumanReadableDate(d)}
                </p>
              ))
            : null}
          <small className="opacity-50 text-nowrap">{audit.auditors}</small>
        </div>
        <div className="text-nowrap text-end">
          {minDate ? (
            <div className="small opacity-50">
              {current
                ? 'audit bezig'
                : toHumanReadableTimeDiff(inThePast ? maxDate : minDate)}
            </div>
          ) : null}
          {audit.import_status === 'failed' ? (
            <div className="small text-danger">import mislukt</div>
          ) : null}
          {audit.import_status === 'queued' ||
          audit.import_status === 'importing' ? (
            <div className="small text-primary opacity-50">
              bezig te importeren...
            </div>
          ) : null}
        </div>
      </div>
      <i
        className={classNames('bi bi-chevron-right align-self-center', {
          'text-primary': !inThePast,
        })}
      ></i>
    </ListGroup.Item>
  );
};

const AuditList = ({ audits }) => (
  <>
    <ListGroup>
      {audits.map((audit) => (
        <Audit audit={audit} key={audit.id} />
      ))}
    </ListGroup>
  </>
);

export default function Audits() {
  const { data: audits, error } = useApi(`/audits/`);
  const [filteredAudits, setFilteredAudits] = useState(audits);

  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState(searchParams.get('q') || '');
  const [debouncedKeyword] = useDebounce(keyword, 300);

  /**
   * Set search query param when debouncedKeyword changed. This stores the search query in the url.
   */
  useEffect(() => {
    if (debouncedKeyword) {
      setSearchParams({ q: debouncedKeyword });
    } else {
      setSearchParams({});
    }
  }, [debouncedKeyword, setSearchParams]);

  /**
   * Locally filter results based on search keyword when `keyword` or `audits` changes
   */
  useEffect(() => {
    const q = keyword;
    if (!audits || !audits.length || !q) return setFilteredAudits(audits);

    setFilteredAudits(() =>
      audits.filter((audit) =>
        [
          audit.auditee,
          String(audit.auditee_number),
          audit.auditors,
          audit.id,
          // audit.audit_date_1,
          // toHumanReadableDate(audit.audit_date_1),
        ]
          .map((i) => i.toLowerCase())
          .join(' ')
          .includes(q.toLowerCase())
      )
    );
  }, [keyword, audits]);

  return (
    <>
      <Nav />
      <main className="container d-flex flex-column align-items-center p-5">
        <h1>Infozoeker</h1>
        <p className="lead mb-0">Selecteer of zoek een audit uit de lijst.</p>

        {error ? <Alert variant="danger">{error.message}</Alert> : null}
        {!filteredAudits && !error ? <Spinner /> : null}

        {filteredAudits ? (
          <>
            <Form.Control
              className="my-5 w-75 rounded-pill px-3"
              placeholder="Zoek in de lijst met audits..."
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div style={{ maxWidth: '600px' }}>
              <AuditList audits={filteredAudits} />
            </div>
          </>
        ) : null}
      </main>
    </>
  );
}
