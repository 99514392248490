import React from 'react';
import { Link } from 'react-router-dom';
import clampText from '../utils/clamped-text';
import { ListGroup } from 'react-bootstrap';
import classNames from 'classnames';

export default function SearchResult({
  result,
  rank,
  audit_id,
  onClick,
  active,
  showScore = true,
  link = true,
  seen = false,
}) {
  const maxLength = 300;
  const text = result.highlight
    ? result.highlight.join(" <span className='opacity-50'>...</span> ")
    : result.content;
  const content = clampText({ text, maxLength });
  return (
    <ListGroup.Item
      action
      as={link ? Link : undefined}
      to={
        link
          ? `/audits/${audit_id}/files/${result.file_id}?document_id=${result.id}`
          : undefined
      }
      key={result.id}
      onClick={onClick}
      className={classNames('d-flex gap-2 py-3 justify-content-between', {
        'opacity-50': seen,
        active,
      })}
    >
      <div>
        {content && result.highlight ? (
          <span
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ) : (
          content
        )}
      </div>
      <small className="opacity-50 text-nowrap">
        p. {result.bboxes == null ? result.page : result.page + 1}
      </small>
    </ListGroup.Item>
  );
}
