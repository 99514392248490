import useSWR from 'swr';
import { host } from './use-api';
import { supabase } from '../supabaseClient';

export default function useQuery(query, audit_id, fileFilter = null) {
  const norm =
    fileFilter != null &&
    fileFilter.startsWith('norm-') &&
    fileFilter.length === 6
      ? Number(fileFilter.split('-')[1])
      : null;
  const file_id = norm == null ? fileFilter : null;

  return useSWR(
    [query, audit_id, file_id, norm],
    async (query, audit_id, file_id, norm) => {
      const {
        data: {
          session: { access_token: token },
        },
      } = await supabase.auth.getSession();
      if (query == null || query === '') {
        return await new Promise((resolve) => resolve(null));
      }

      return await fetch(`${host}/query`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query,
          audit_id,
          file_id,
          norm,
        }),
      }).then((res) => (res.ok ? res.json() : null));
    }
  );
}
