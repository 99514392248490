import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import {
  PdfLoader,
  PdfHighlighter,
  Popup,
  Highlight,
  AreaHighlight,
} from 'react-pdf-highlighter';
import { host } from '../data/use-api';
import Spinner from './spinner';
import Alert from 'react-bootstrap/Alert';
import { supabase } from '../supabaseClient';

const HighlightPopup = ({ comment }) =>
  comment?.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

function Pdf({ file_id, file, document_id, searchResults = [] }) {
  const [pdfFetchError, setPDFFetchError] = useState(null);
  const [scrollViewTo, setScrollViewTo] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchToken() {
      const {
        data: {
          session: { access_token },
        },
      } = await supabase.auth.getSession();
      setToken(access_token);
    }
    fetchToken();
  }, [file_id]);

  function highlightFromDocument(document) {
    const { content, id, page, bbox: boundingRect, bboxes } = document;
    const highlight = { content, id };
    if (bboxes == null || bboxes.length === 0) {
      highlight.position = {
        pageNumber: page,
        boundingRect: boundingRect,
        rects: [boundingRect],
      };
    } else {
      highlight.position = {
        pageNumber: page + 1,
        boundingRect: boundingRect,
        rects: bboxes.filter((b) => b.page === page),
      };
    }
    return highlight;
  }

  const highlights = useMemo(
    () => searchResults.map(highlightFromDocument),
    [searchResults]
  );

  useEffect(() => {
    if (document_id && scrollViewTo != null) {
      const hl = highlights.find((e) => e.id === document_id);
      if (hl && scrollViewTo) {
        scrollViewTo(hl);
      }
    }
  }, [document_id, scrollViewTo, highlights]);

  if (!file_id || !file || !file.filename || !token) {
    return <Spinner />;
  }

  if (pdfFetchError) {
    console.error(pdfFetchError);
    return (
      <Alert variant="danger">
        Kon bestand niet downloaden <strong>({pdfFetchError.message})</strong>
      </Alert>
    );
  }

  const url = `${host}/files/${file_id}/${file.filename}`;
  return (
    <PdfLoader
      url={url}
      httpHeaders={{ Authorization: `Bearer ${token}` }}
      beforeLoad={<Spinner />}
      onError={(e) => setPDFFetchError(e)}
    >
      {(pdfDocument) => {
        return (
          <PdfHighlighter
            pdfDocument={pdfDocument}
            enableAreaSelection={(event) => event.altKey}
            onScrollChange={() => {}}
            scrollRef={(scrollTo) => {
              setScrollViewTo(() => scrollTo);
            }}
            onSelectionFinished={
              (position, content) => {}
              // addHighlight({ content, position })
            }
            highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo
            ) => {
              const isTextHighlight = !(
                highlight.content && highlight.content.image
              );

              const component = isTextHighlight ? (
                <Highlight
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                />
              ) : (
                <AreaHighlight
                  isScrolledTo={isScrolledTo}
                  highlight={highlight}
                  onChange={(boundingRect) => {
                    this.updateHighlight(
                      highlight.id,
                      { boundingRect: viewportToScaled(boundingRect) },
                      { image: screenshot(boundingRect) }
                    );
                  }}
                />
              );

              return (
                <Popup
                  popupContent={<HighlightPopup {...highlight} />}
                  onMouseOver={(popupContent) =>
                    setTip(highlight, (highlight) => popupContent)
                  }
                  onMouseOut={hideTip}
                  key={index}
                  children={component}
                />
              );
            }}
            highlights={highlights}
          />
        );
      }}
    </PdfLoader>
  );
}

export default Pdf;
