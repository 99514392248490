import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { supabase } from './supabaseClient';

import Audits from './pages/audits';
import Audit from './pages/audit';
import Search from './pages/search';
import Login from './pages/auth/login';
import Logout from './pages/auth/logout';
import ResetPassword from './pages/auth/reset-password';
import ConfirmLogin from './pages/auth/confirm';

function App() {
  const [session, setSession] = useState(() => null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          !session ? <Login /> : <Navigate to="/audits" replace={true} />
        }
      />
      <Route
        path="/auth/login"
        element={
          !session ? <Login /> : <Navigate to="/audits" replace={true} />
        }
      />
      <Route
        path="/auth/confirm"
        element={
          !session ? <ConfirmLogin /> : <Navigate to="/audits" replace={true} />
        }
      />
      <Route
        path="/auth/logout"
        element={
          !session ? <Navigate to="/auth/login" replace={true} /> : <Logout />
        }
      />
      <Route
        path="/auth/reset-password"
        element={!session ? <Login /> : <ResetPassword />}
      />
      <Route path="/audits" element={!session ? <Login /> : <Audits />} />
      <Route
        path="/audits/:audit_id"
        element={!session ? <Login /> : <Audit />}
      />
      <Route
        path="/audits/:audit_id/search"
        element={!session ? <Login /> : <Search />}
      />
    </Routes>
  );
}

export default App;
