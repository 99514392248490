import React, { useMemo } from 'react';
import SearchResult from './search-result';
import FileIcon from './file-icon';

import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
// import classNames from 'classnames';

export default function SearchResultGroup({
  file,
  audit_id,
  onClick,
  document_id,
}) {
  const n_docs =
    file.documents.length > 1
      ? `${file.documents.length} resultaten`
      : `${file.documents.length} resultaat`;
  const sortedDocuments = useMemo(() => {
    return file.documents.sort((a, b) => {
      if (a.page !== b.page) return a.page - b.page;
      return a.bbox.y1 - b.bbox.y1;
    });
  }, [file.documents]);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item
        eventKey="0"
        className="list"
        style={{ overflow: 'clip' }}
      >
        <Accordion.Header className="sticky-top">
          <FileIcon file={file} />
          <div>
            <div>{file.filename}</div>
            <div className="small mt-1 opacity-50">{n_docs}</div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <ListGroup variant="flush">
            {sortedDocuments
              .sort((a, b) => {
                if (a.page !== b.page) return a.page - b.page;
                return a.bbox.y1 - b.bbox.y1;
              })
              .map((doc, j) => (
                <SearchResult
                  active={doc.id === document_id}
                  link={false}
                  onClick={() => onClick(doc)}
                  audit_id={audit_id}
                  result={doc}
                  key={doc.id}
                />
              ))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
