import React, { useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

import Nav from '../components/nav';
import Pdf from '../components/pdf';
import FileTitle from '../components/file-title';
import useQuery from '../data/use-query';
import { useApi } from '../data/use-api';
import SearchResultGroup from '../components/search-result-group';
import SearchBar from '../components/searchbar';
import classNames from 'classnames';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Spinner from '../components/spinner';
import { CloseButton } from 'react-bootstrap';

const ResultsList = ({ results, onClick, document_id }) => {
  if (!results) {
    return <Spinner />;
  }
  if (results && results.files && results.files.length === 0) {
    return <div className="text-center opacity-50 mx-3">Geen resultaten</div>;
  }
  return (
    <>
      {results.files.map((file, i) => (
        <SearchResultGroup
          onClick={onClick}
          document_id={document_id}
          file={file}
          key={file.file_id}
        />
      ))}
      <div className="d-flex mt-3 mb-5 pb-5 px-3 align-items-center justify-content-center">
        <hr className="flex-grow-1" />
        <div className="text-center opacity-50 mx-3">Einde zoekresultaten</div>
        <hr className="flex-grow-1" />
      </div>
    </>
  );
};

const ResultsBadge = ({ searchResults }) => {
  if (!searchResults) return null;
  const totalFiles =
    searchResults && searchResults.files ? searchResults.files.length : 0;
  const totalResults =
    searchResults && searchResults.files
      ? searchResults.files.reduce((acc, file) => {
          return acc + file.documents.length;
        }, 0)
      : 0;
  return (
    <Badge pill bg="white" className="text-primary">
      {totalResults} {totalResults === 1 ? 'resultaat' : 'resultaten'}
      {' in '}
      {totalFiles} {totalFiles === 1 ? 'bestand' : 'bestanden'}
    </Badge>
  );
};

const SearchResults = ({
  searchResults,
  document_id,
  setActiveDocument,
  query,
}) => {
  return (
    <>
      <div className="sticky-top mb-3 d-flex flex-column">
        <div className="d-flex align-items-center mb-1">
          <h4 className="mb-0">Zoekresultaten</h4>
          <div className="ms-auto">
            <ResultsBadge searchResults={searchResults} />
          </div>
        </div>
      </div>
      <div
        className="overflow-y-scroll d-flex flex-column rounded pb-5"
        style={{ gap: '1rem' }}
      >
        {query !== '' ? (
          <ResultsList
            results={searchResults}
            onClick={setActiveDocument}
            document_id={document_id}
          />
        ) : (
          'Voer een zoekterm in.'
        )}
      </div>
    </>
  );
};

export default function Search() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  // Parse parameters
  const { audit_id } = useParams();
  const query = urlSearchParams.get('q') ? urlSearchParams.get('q') : '';
  const document_id = urlSearchParams.get('document_id');
  const file_id = urlSearchParams.get('file_id');
  const view = urlSearchParams.get('view');
  const fileFilter = urlSearchParams.get('fileFilter');

  const { data: searchResults, error: searchError } = useQuery(
    query,
    audit_id,
    fileFilter
  );
  const { data: activeDocument, error: documentError } = useApi(
    document_id != null ? `/documents/${document_id}` : null
  );
  const { data: file } = useApi(file_id != null ? `/files/${file_id}` : null);

  if (searchError || documentError) {
    if (searchError) console.error(searchError);
    if (documentError) console.error(documentError);
  }

  function updateSearchParams(params) {
    const searchParams = new URLSearchParams(urlSearchParams);
    Object.keys(params).forEach((key) => {
      if (params[key] == null || params[key] === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, params[key]);
      }
    });
    setUrlSearchParams(searchParams);
  }

  function setActiveDocument(result) {
    if (result) {
      updateSearchParams({ document_id: result.id, file_id: result.file_id });
    } else {
      updateSearchParams({ document_id: null });
    }
  }

  function setQuery(query) {
    // Omit view and selected highlight from new searchParams
    updateSearchParams({ q: query, view: null, document_id: null });
  }

  function setFilter(fileFilter) {
    // Always omit view from new searchParams
    const params = { fileFilter, view: null };

    // If file_id does not match fileFilter, reset file_id and document_id
    if (fileFilter.startsWith('norm-')) {
      const norm = fileFilter.split('-')[1];
      if (file != null && !file.norms.includes(norm)) {
        params.file_id = null;
        params.document_id = null;
      }
    } else if (file_id !== fileFilter) {
      params.file_id = null;
      params.document_id = null;
    }

    updateSearchParams(params);
  }

  function setView(view) {
    updateSearchParams({ view });
  }

  function getResultsInFile(file_id, searchResults, activeDocument) {
    if (searchResults == null || searchResults.files == null) {
      return activeDocument != null ? [activeDocument] : [];
    }
    const filteredResults = searchResults.files.find(
      (e) => e.file_id === file_id
    );
    if (filteredResults == null) {
      return activeDocument != null ? [activeDocument] : [];
    }
    return filteredResults.documents;
  }

  const searchResultsInFile = useMemo(
    () => getResultsInFile(file_id, searchResults, activeDocument),
    [file_id, searchResults, activeDocument]
  );

  return (
    <>
      <Nav />
      <div className="mb-3" />
      <main
        className={classNames('search-view', {
          'search-view__hide-results': view === 'read',
        })}
      >
        <div className="searchbar container">
          <SearchBar
            autoFocus
            query={query}
            setQuery={setQuery}
            fileFilter={fileFilter}
            setFileFilter={setFilter}
          />
        </div>
        <div className="searchresults">
          <SearchResults
            searchResults={searchResults}
            document_id={document_id}
            fileFilter={fileFilter}
            setFilter={setFilter}
            setActiveDocument={setActiveDocument}
            query={query}
          />
        </div>
        <div className="pdfviewer">
          {file_id && file ? (
            <>
              <div className="bg-primary px-3 py-2 sticky-top d-flex justify-content-between align-items-center rounded-top">
                <h6 className="text-white mb-0 flex-shrink-1">
                  <FileTitle file_id={file_id} />
                </h6>
                <div
                  className="d-flex align-items-center align-self-start flex-shrink-0"
                  style={{ columnGap: '.5rem' }}
                >
                  {/* <Button
                    as="a"
                    variant="outline-light"
                    size="sm"
                    href={`${host}/files/${file_id}/${file.filename}`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right me-1"></i>
                    Open in nieuw venster
                  </Button> */}
                  {fileFilter !== file_id ? (
                    <Button
                      className="flex-shrink-0"
                      size="sm"
                      variant="outline-light"
                      onClick={() => setFilter(file_id)}
                    >
                      <i className="bi bi-funnel me-1"></i>
                      Zoek in dit bestand
                    </Button>
                  ) : null}
                  {view === 'read' ? (
                    <Button
                      className="flex-shrink-0"
                      size="sm"
                      variant="outline-light"
                      onClick={() => setView(undefined)}
                    >
                      <i className="bi bi-fullscreen-exit me-1"></i>
                      Vorig formaat
                    </Button>
                  ) : null}
                  {view !== 'read' ? (
                    <Button
                      className="flex-shrink-0"
                      size="sm"
                      variant="outline-light"
                      onClick={() => setView('read')}
                    >
                      <i className="bi bi-arrows-fullscreen me-1"></i>
                      Lezen
                    </Button>
                  ) : null}
                  <CloseButton
                    variant="white"
                    onClick={() =>
                      updateSearchParams({
                        file_id: null,
                        view: null,
                        document_id: null,
                      })
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  position: 'relative',
                  flexGrow: '1',
                  backgroundColor: '#333',
                }}
              >
                <Pdf
                  file_id={file_id}
                  file={file}
                  document_id={document_id}
                  searchResults={searchResultsInFile}
                  key={file_id}
                />
              </div>
            </>
          ) : null}
        </div>
      </main>
    </>
  );
}
