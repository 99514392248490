import classNames from 'classnames';

const FileIcon = ({ file, className = 'me-3' }) => {
  const isFile = (type) => {
    return (
      file.filetype === type ||
      (file.filename || '').toLowerCase().endsWith(`.${type}`)
    );
  };

  let icon = 'file-earmark';
  if (isFile('pdf')) {
    icon = 'file-earmark-pdf';
  }
  if (isFile('docx')) {
    icon = 'file-earmark-text';
  }
  if (isFile('doc')) {
    icon = 'file-earmark-text';
  }
  if (isFile('xlsx')) {
    icon = 'file-earmark-spreadsheet';
  }
  return (
    <i
      className={classNames(`bi bi-${icon}`, className)}
      style={{ fontSize: '1.5rem' }}
    ></i>
  );
};

export default FileIcon;
